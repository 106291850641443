


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Modal
   ========================================================================== */

   .modal {
    --spacing: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .modal__close {
    display: block;
    position: fixed;
    background: rgba(116, 116, 116, 0.5);
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal__inner {
    position: relative;
    width: calc(var(--spacing) * 36);
    max-width: 100%;
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 1.5);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
  }
  
  .modal__close-btn {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .modal__close-btn svg {
    width: var(--spacing);
    height: var(--spacing);
    fill: currentColor;
    opacity: 0.5;
  }
  
  .modal__close-btn:hover svg {
    opacity: 1;
  }


  .react-multiple-carousel__arrow--right {
    /* right: calc(4% + 1px); */
    background-color: rgba(106, 106, 106, 0.678) !important;
   
  }


  .react-multiple-carousel__arrow--right:hover {
   
    background-color: rgba(30, 30, 30, 0.833) !important;
   
  }


  
  .react-multiple-carousel__arrow--left {
    /* right: calc(4% + 1px); */
    background-color: rgba(106, 106, 106, 0.678) !important;
   
  }


  .react-multiple-carousel__arrow--left:hover {
   
    background-color: rgba(30, 30, 30, 0.833) !important;
   
  }


  .slick-prev::before, .slick-next::before {
   
    color: #5AB491 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  .slick-dots {
    position: relative !important;
    bottom: -5px !important;
    
  }

  /* p{
     padding-bottom: 10px;
  } */