@tailwind base;
@tailwind components;
@tailwind utilities;

@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('./assets/fonts/verdana.ttf') format('ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p{
  font-family: Verdana, Geneva, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}



span{
  font-family: Verdana, Geneva, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient{
  background: rgb(252,255,0);
  background: linear-gradient(90deg, #e35972 0%, #f2c237 25%, #49a3d4 50%, #6fa84a 75%); 
  
}

.bg-gradient-to-b from-green-400 via-blue-500 to-green-500{
  background: rgb(252,255,0);
  background: linear-gradient(180deg, #e35972 , #f07e64 ); 
  
}
.bg-gradient-to-b from-green-400 via-blue-500 to-green-500{
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, #136492 , #49a3d4 ); 
  
}

.menu-item{
  
  position: relative;
  z-index: 15;
  margin-bottom: -10px;

}


.menu-item::before {
  content: "";
  height: 46.3906px;
  width: 46.3906px;
  position: absolute;
  left: -42px;
  bottom: 10;
  background-image: url('/src/assets/header/menu-wing.svg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 16;
}

img, video {
  max-width: none !important;
  
}

 .menu-item::after {
  content: "";
  height: 46.3906px;
  width: 46.3906px;
  position: absolute;
  right: -42px;
  bottom: -10px;
  background-image: url('/src/assets/header/menu-wing.svg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotateY(180deg);
  z-index: 16;
}